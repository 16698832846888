@import 'styles/includes.scss';
@import 'styles/variables.scss';

.QuickLink {
    font-family: $fontBrand;
    font-weight: bold;
    background-color: $colorWhite;
    border-radius: 10px;
    height: 64px;
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 16px;

    &__Link {
        text-decoration: none;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 20px;
        position: relative;
        z-index: 200;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 0;
            clip-path: circle(18px at calc(100% - 3.8rem) 50%);
            transition: clip-path $transition;

            .QuickLink:hover &,
            .QuickLink:focus & {
                clip-path: circle(150% at calc(100% - 3.8rem) 50%);

                @supports (-ms-ime-align: auto) {
                    clip-path: none;
                }
            }

            @supports (-ms-ime-align: auto) {
                clip-path: none;
            }
        }

        &--Pink.QuickLink__Link::after {
            background-color: $colorPink;

            @supports (-ms-ime-align: auto) {
                background-color: $colorWhite;
                transition: color 0.3s;
            }

            .QuickLink:hover &,
            .QuickLink:focus & {
                @supports (-ms-ime-align: auto) {
                    background-color: $colorPink;
                }
            }
        }

        &--Black.QuickLink__Link::after {
            background-color: $colorBlack800;
        }
    }

    &__Title {
        position: relative;
        display: block;
        width: 100%;
        font-size: 1.7rem;
        line-height: 2.4rem;
        letter-spacing: 0.04rem;
        color: $colorBlack800;
        z-index: 20;
        transition: all $transition;

        @include media(l) {
            font-size: 1.9rem;
            line-height: 3.6rem;
            letter-spacing: 0.02rem;
        }

        .QuickLink:hover &,
        .QuickLink:focus & {
            color: $colorWhite;
        }
    }

    &__Icon {
        position: relative;
        display: flex;
        min-width: 36px;
        min-height: 36px;
        justify-content: center;
        align-items: center;
        z-index: 10;
        margin-left: $spacingL * 2;

        @supports (-ms-ime-align: auto) {
            border-radius: 50%;
            padding: 1rem;
            background-color: $colorPink;
        }
    }
}
