@import 'styles/includes.scss';

.CategoryPage {
    background-color: $colorWhiteBackground;

    flex: 1;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        flex: none;
    }

    &__Row {
        padding-top: 1.6rem;

        @include media(m) {
            padding-top: 5.4rem;
        }
    }

    &__CrossLinks {
        display: flex;
        padding-top: 1.6rem;
        padding-bottom: 8rem;

        @include media(m) {
            padding-top: 5.4rem;
        }
        @include media(xl) {
            width: 144rem;
            margin: 0 auto;
        }
    }
}
