@import 'styles/includes.scss';
@import 'styles/variables.scss';

.CrossLinks {
    @extend %container;
    font-family: $fontBrand;

    &__Links {
        display: flex;
        flex-direction: column;
        margin-left: -24px;
        max-width: calc(75rem + 2.4rem);
    }

    &__Title {
        display: block;
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 3.43rem;
        letter-spacing: 0.04rem;
        color: $colorBlack800;
        margin-bottom: 28px;
    }

    & .QuickLink {
        margin-bottom: $spacingL;
    }
}
